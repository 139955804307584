import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChooseDateForm from 'components/forms/booking/ChooseDateForm';
import CompleteOrderForm from 'components/forms/booking/CompleteOrderForm';
import ProvideDataForm from 'components/forms/booking/ProvideDataForm';
import PayOnlineForm from 'components/forms/booking/PayOnlineForm';
import { success } from 'helpers/reactToaster';
import { getFormValues } from 'redux-form';
import { prepareCheckout } from '../../../services/payment/actions';

export class BookForm extends Component {
  state = {
    paypalSuccess: false,
    payment: null,
    cost: null,
    date: null,
  };

  onSuccess = (payment) => {
    this.setState({
      paypalSuccess: true,
      payment,
    });
    success('Payment Successful');
  };

  setCost = (cost) => {
    this.setState({ cost });
  };

  setDate = (date) => {
    const { setDateState } = this.props;
    setDateState(date);
    this.setState({ date });
  };

  render() {
    const { paypalSuccess, payment, cost, date } = this.state;
    const {
      type,
      page,
      dateState,
      adultState,
      childState,
      costState,
      originalCostState,
      firstname,
      lastname,
      phone,
      pickup,
      email,
      nextPage,
      disabledDays,
      options,
      onSubmit,
      price,
      agent,
      commission,
      isprivate,
      toggleTerms,
      setDateState,
      setAdultState,
      setChildState,
      setCostState,
      setFirstname,
      setLastname,
      setPhone,
      setEmail,
      setPickup,
      setIsprivate,
      validateEmail,
      formValues,
      transportType,
      pickups,
      tourPickups,
      tour,
      tours,
      languages,
      setLanguage,
      language,
    } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div onClick={(event) => event.stopPropagation()}>
        {page === 1 && (
          <ChooseDateForm
            onSubmit={() =>
              nextPage(price.tourtype === 'private' || isprivate ? 3 : 2)
            }
            type={type}
            disabledDays={disabledDays}
            options={options}
            agent={agent}
            setDate={this.setDate}
            price={price}
            isprivate={isprivate}
            date={date}
            dateState={dateState}
            setIsprivate={setIsprivate}
            setDateState={setDateState}
            tours={tours}
          />
        )}
        {page === 2 && (
          <CompleteOrderForm
            onSubmit={() => nextPage(3)}
            price={price}
            agent={agent}
            onPrevious={() => nextPage(1)}
            commission={commission}
            setCost={this.setCost}
            date={date}
            adultState={adultState}
            childState={childState}
            cost={costState}
            originalCost={originalCostState}
            setAdultState={setAdultState}
            setChildState={setChildState}
            setCostState={setCostState}
          />
        )}
        {page === 3 && (
          <ProvideDataForm
            onSubmit={
              price.tourtype === 'private' || isprivate || agent
                ? onSubmit
                : () => nextPage(4)
            }
            onPrevious={() =>
              nextPage(price.tourtype === 'private' || isprivate ? 1 : 2)
            }
            agent={agent}
            price={price}
            isprivate={isprivate}
            firstname={firstname}
            lastname={lastname}
            phone={phone}
            email={email}
            pickup={pickup}
            setFirstname={setFirstname}
            setLastname={setLastname}
            setEmail={setEmail}
            setPhone={setPhone}
            setPickup={setPickup}
            validateEmail={validateEmail}
            transportType={transportType}
            pickups={pickups}
            tourPickups={tourPickups}
            language={languages}
            setLanguage={setLanguage}
            languageSelected={language}
          />
        )}
        {page === 4 && (
          <PayOnlineForm
            onSubmit={onSubmit}
            onSuccess={this.onSuccess}
            paypalSuccess={paypalSuccess}
            onPrevious={() => nextPage(3)}
            payment={payment}
            cost={cost}
            toggleTerms={toggleTerms}
            formValues={formValues}
            tour={tour}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    formValues: getFormValues('booking')(state),
  }),
  {
    prepareCheckout,
  },
)(BookForm);
