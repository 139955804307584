import { all, takeLatest, call, put } from 'redux-saga/effects';
import Firestore from 'firebase-service/Firestore';
import 'firebase/firestore';
import * as types from 'constants/actions';
import * as fields from 'constants/fields';
import * as actions from './actions';

const firestore = new Firestore();

function* getImages() {
  const result = yield call(
    firestore.getRSF().firestore.getCollection,
    firestore.get().collection('gallery'),
  );

  yield put(
    actions.setImages(firestore.sanitizeSnapshot(result, fields.GALLERY)),
  );
}

function* gallerySaga() {
  yield all([takeLatest(types.GET_IMAGES, getImages)]);
}
export default gallerySaga;
