import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { dropdownField, calendarField } from 'helpers/reduxForms';
import BookHeader from 'components/forms/booking/BookHeader';
import BookFooter from 'components/forms/booking/BookFooter';
import AlertMessage from 'components/utils/AlertMessage';
import { ScrollPanel } from 'primereact/scrollpanel';
import moment from 'moment';

export class ChooseDateForm extends Component {
  state = {
    date: null,
  };

  setDate = (value) => {
    const { setDateState } = this.props;
    setDateState(value);
    this.setState({
      date: value,
    });
    return value;
  };

  onSubmit = (values) => {
    const { onSubmit, setDate, tours } = this.props;
    const { date } = this.state;
    const currentDate = moment().format('MM/DD/YYYY');
    const selectedDate = moment(date).format('MM/DD/YYYY');
    const currentTime = moment(new Date(), 'HH:mm').format('HH:mm');
    const tourStart = moment(`${tours.schedule.start}`, 'HH:mm').format(
      'HH:mm',
    );
    const diff = moment
      .utc(`${currentTime}`, 'HH:mm')
      .diff(moment.utc(`${tourStart}`, 'HH:mm'), 'hours');
    console.log(diff, tourStart, currentTime);
    if (currentDate === selectedDate) {
      if (diff < 12) {
        alert('Cannot book a tour at this time');
        return;
      }
    }
    setDate(date);
    onSubmit(values);
  };

  renderRequireDate = () => {
    const { dateState } = this.props;
    return (
      !dateState && (
        <div className="row section">
          <div className="col text-center">
            <AlertMessage message="Date is required" />
          </div>
        </div>
      )
    );
  };

  render() {
    const {
      handleSubmit,
      type,
      options,
      disabledDays,
      agent,
      price,
      isprivate,
      dateState,
      setIsprivate,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <BookHeader
          page={1}
          title="Choose Date"
          isprivate={isprivate}
          setIsprivate={setIsprivate}
        />
        <ScrollPanel style={{ maxHeight: '500px' }}>
          {type === 'Specific Dates' ? (
            <div style={{ padding: '50px 50px', minHeight: 300 }}>
              <Field
                name="date"
                component={dropdownField}
                options={options}
                normalize={this.setDate}
                placeholder="Select a Date"
                format={this.formatSpecificDate}
              />
              {this.renderRequireDate()}
            </div>
          ) : (
            <div>
              <Field
                name="date"
                component={calendarField}
                inline
                disabledDays={disabledDays}
                minDate={new Date()}
                monthNavigator
                normalize={this.setDate}
              />
              {this.renderRequireDate()}
            </div>
          )}
        </ScrollPanel>
        <BookFooter
          page={1}
          disabled={!dateState}
          error={!dateState && 'Please select date first'}
          agent={agent}
          tourtype={price.tourtype}
          isprivate={isprivate}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'booking', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(ChooseDateForm);
