import React from 'react';
import { Button } from 'primereact/button';

export default function BookLogin({ tour, history, props }) {
  return (
    // <div className="container fixed-bottom animated bounceInUp text-right book-login">
    //   <Button className="red-button" style={{padding: '10px 0'}} label="Login to book now!" onClick={() => history.push('/login')} />
    // </div>
    <div className="container fixed-bottom book-container shadow animated bounceInUp">
      <div className="row d-flex mt-2 p-3">
        <div className="col" style={{ display: 'flex', alignSelf: 'center' }}>
          <span>{tour.name}</span>
        </div>
        <div className="col d-flex justify-content-end">
          <p
            style={{
              alignItems: 'center',
              marginBottom: '0px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {/* <span className={(tour.pricing.tourtype !== 'private' && this.getUserinfo(Cookies.get('user')) !== undefined && this.getUserinfo(Cookies.get('user')).agent && 'strikethrough') || ''}> */}
            <span style={{ fontWeight: 'bold', marginRight: '12px' }}>
              {tour.pricing.tourtype === 'private'
                ? 'Private'
                : ` € ${Number(tour.pricing.price.adult).toFixed(2)}`}
            </span>
          </p>
          <Button
            className="red-button"
            style={{ height: '40px', width: '140px' }}
            label="BOOK NOW"
            onClick={() =>
              history.push({
                pathname: '/login',
                state: {
                  from: props.location.pathname,
                },
              })
            }
          />
        </div>
      </div>
    </div>
  );
}
