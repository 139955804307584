import { all, takeLatest, call, put } from 'redux-saga/effects';
import * as types from 'constants/actions';
import * as fields from 'constants/fields';
import Firestore from 'firebase-service/Firestore';
import * as actions from './actions';

const firestore = new Firestore();

function* getRestaurant() {
  const result = yield call(
    firestore.getRSF().firestore.getCollection,
    firestore.get().collection('restaurant'),
  );

  yield put(
    actions.setRestaurants(
      firestore.sanitizeSnapshot(result, fields.RESTAURANT),
    ),
  );
}

function* restaurantsSaga() {
  yield all([takeLatest(types.GET_RESTAURANTS, getRestaurant)]);
}

export default restaurantsSaga;
