import * as types from 'constants/actions';

const initState = {
  images: [],
};

export default (state = initState, action) => {
  const { images, type } = action;

  switch (type) {
    case types.SET_IMAGES:
      return { ...state, images };
    default:
      return { ...state };
  }
};
