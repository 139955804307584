import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Button } from 'primereact/button';
import { addOrder } from '../../../services/orders/actions';
import { Spinner } from '../../../routes/OrderSummary';
import RevolutCheckout from '@revolut/checkout';
import firebase from 'firebase/app';
import 'firebase/functions';
import FIREBASE_CONFIG from 'firebase-service/config';
import { InputText } from 'primereact/inputtext';
import revolutLogo from '../../../images/revolut-logo.png';

const TextInput = ({
  name,
  label,
  value,
  isCorrect,
  handleValue,
  className,
}) => (
  <div
    style={{ maxWidth: 'max-content', width: '80%' }}
    className="d-flex flex-column">
    {label && <label htmlFor={name}>{label}</label>}
    <InputText
      className={`${className}`}
      id={name}
      value={value}
      onChange={handleValue}
      style={{ borderColor: isCorrect ? 'green' : '' }}
    />
  </div>
);

if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_CONFIG);
}

const functions = firebase.functions();
const auth = firebase.auth();

export const CardPayment = ({ formValues, tour }) => {
  const { checkoutOrder } = useSelector((state) => state.payments);
  const [transactionCompleted, setTransactionCompleted] = useState(false);
  const [orderID, setOrderID] = useState(false);
  const dispatch = useDispatch();
  const date = moment().format();
  const history = useHistory();

  const revolutCheckoutRef = useRef(null);
  const cardElementRef = useRef(null);
  const [isRevolutLoading, setIsRevolutLoading] = useState(false);
  const [cardholderName, setCardholderName] = useState('');
  const [revolutError, setRevolutError] = useState(undefined);
  const [isCardFieldValid, setIsCardFieldValid] = useState(false);

  const cardholderNameError =
    !cardholderName ||
    cardholderName.length < 3 ||
    cardholderName.split(' ').length < 2 ||
    cardholderName.split(' ')[1]?.length < 1;

  const isPayButonDisabled =
    !cardholderName ||
    cardholderName.length < 3 ||
    cardholderNameError ||
    !isCardFieldValid;

  // create order
  const createOrder = (data, actions) =>
    actions.order
      .create({
        purchase_units: [
          {
            description: checkoutOrder?.tourName,
            amount: {
              currency_code: 'EUR',
              value: formValues.amount,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });

  // check Approval
  const onApprove = (data, actions) =>
    actions.order.capture().then((details) => {
      // eslint-disable-next-line camelcase
      const { payer, status, purchase_units } = details;
      const body = {
        ...checkoutOrder,
        payment: {
          paypal: true,
          ...details,
        },
      };
      setTransactionCompleted(true);
      dispatch(addOrder(body));
    });

  // capture likely error
  const onError = (data, actions) => {
    console.log('An Error occured with your payment');
    // eslint-disable-next-line no-alert
    window.alert("'An Error occured with your payment");
  };

  const handleSuccessfulTransaction = ({
    token: transactionID,
    id: orderID,
    reference,
  }) => {
    const body = {
      ...checkoutOrder,
      payment: {
        transactionInfo: {
          transactionStatus: 'Accepted',
          rrn: orderID,
          transactionId: transactionID,
          merchantReference: reference,
        },
      },
    };

    dispatch(addOrder(body));
    setTransactionCompleted(true);
  };

  //
  // HERE STARTS REVOLUT LOGIC
  const handleCardholderName = (e) => {
    const newValue = e.target.value;
    if (newValue[0] === ' ') return;
    setCardholderName(newValue);
  };

  const submitCardPayment = () => {
    if (!revolutCheckoutRef.current) return;
    setRevolutError(undefined);
    revolutCheckoutRef.current.submit({ name: cardholderName });
  };

  const fetchOrderData = async (orderData) => {
    if (!auth.currentUser) {
      console.error('User is not logged in');
      return;
    }

    const getRevolutOrderData = functions.httpsCallable('createRevolutOrder');

    try {
      const {
        data: {
          token,
          id,
          merchant_order_data: { reference },
        },
      } = await getRevolutOrderData(orderData);

      return { token, id, reference };
    } catch (error) {
      console.error('Error fetching revolut order: ', error.message);
    }
  };

  const createRevolutOrder = async () => {
    if (!checkoutOrder) return;
    const {
      amount,
      leadpassenger: {
        email,
        fullname: { firstname, lastname },
      },
      ref,
    } = checkoutOrder;

    const fullname = `${firstname} ${lastname}`;
    const description = `Reference: #${ref} - Name: ${fullname}`;
    const ISO4217Amount = Math.round(amount * 100);
    const paymentData = {
      amount: ISO4217Amount,
      currency: 'EUR',
      customer: { email },
      description,
      merchant_order_data: { reference: `#${checkoutOrder.ref}` },
    };

    try {
      setIsRevolutLoading(true);
      const { token, id, reference } = await fetchOrderData(paymentData);
      const { createCardField } = await RevolutCheckout(token, 'prod');
      revolutCheckoutRef.current = createCardField({
        target: cardElementRef.current,
        styles: {
          default: { borderBottom: '2px solid gray' },
        },
        onSuccess() {
          handleSuccessfulTransaction({ token, id, reference });
        },
        onError(error) {
          setRevolutError(error.message);
        },
        onStatusChange(status) {
          const { invalid, completed } = status;
          const isValid = !invalid && completed;
          setIsCardFieldValid(isValid);
          if (!status.invalid) {
            setRevolutError(undefined);
          }
        },
        onValidation(validation) {
          if (validation[0]?.message) {
            setRevolutError(validation[0].message);
          }
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsRevolutLoading(false);
    }
  };

  useEffect(() => {
    createRevolutOrder();
    return () => {
      if (revolutCheckoutRef.current) {
        revolutCheckoutRef.current.destroy();
      }
    };
  }, [checkoutOrder]);
  // HERE ENDS REVOLUT LOGIC
  //

  useEffect(() => {
    if (transactionCompleted) {
      setTimeout(() => {
        history.push('/order');
      }, 3000);
    }
  }, [transactionCompleted]);

  // Af85j_n1Sdn9Pv77gCdXeGhKPqCoYZbjaGHU6cwzurLXvW_MVajFD3oOpNthgWB8xCKLBg6GNlg3xJdh
  // AYBuAkj5ByUYlbfiFQgkkHocjr1-dC0KKMbkpI0YX0Lpg5ZmlBiMa8e25RfTIFggN9JDgsWbGYxW_mUV
  // ATmYsbK-WbZCOtkmtJD-BlNULaJSX0ekUkzRgh2bCcv-p-a9ltblj0Z0jFDhsSG3Ym0xX37F_k2Ojt3E
  const ENV = process.env.NODE_ENV === 'production' ? 'sandbox' : 'sandbox';
  const CLIENT = {
    sandbox:
      'ATmYsbK-WbZCOtkmtJD-BlNULaJSX0ekUkzRgh2bCcv-p-a9ltblj0Z0jFDhsSG3Ym0xX37F_k2Ojt3E',
    production: ENV,
  };
  return (
    <div className="application" style={{ textAlign: 'center' }}>
      <div className="total-container">
        <label className="total">Total payment to be deducted: </label>
        <span className="theme-red"> € {checkoutOrder?.amount}</span>
      </div>
      <div>
        {transactionCompleted && (
          <div>
            <Spinner />
            <div className="order-redirect">
              Please wait... Redirecting to Orders page
            </div>
          </div>
        )}
      </div>
      {/* <div className="payment-tab">
        <Button className="paycard-tab" label="Pay By Card" />
        <PaypalButton
          client={CLIENT}
          env={ENV}
          commit
          currency="EUR"
          total={checkoutOrder?.amount}
        />
      </div> */}
      <div className="payment-tab">
        <Button className="paycard-tab" label="Pay By Card" />
        <PayPalScriptProvider
          options={{ 'client-id': CLIENT.sandbox, currency: 'EUR' }}>
          <PayPalButtons
            style={{ layout: 'horizontal' }}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        </PayPalScriptProvider>
      </div>

      {/* Revolut */}
      <div className="d-flex flex-column mt-5 align-items-center border w-75 py-5 rounded shadow mx-auto">
        {isRevolutLoading && <Spinner />}
        {revolutCheckoutRef.current && (
          <TextInput
            value={cardholderName}
            isCorrect={!cardholderNameError}
            handleValue={handleCardholderName}
            label="Cardholder's name (as it appears on card)"
            name="cardholders-name"
            className="max-w-50 shadow-sm"
          />
        )}
        <div
          id="card-field"
          style={{ paddingBlock: '25px' }}
          ref={cardElementRef}
        />
        {revolutCheckoutRef.current && (
          <Button
            onClick={submitCardPayment}
            label="Pay"
            disabled={isPayButonDisabled}
            className="shadow"
          />
        )}
        {revolutError && (
          <span className="pt-4 text-danger">{revolutError}</span>
        )}
        <div className="border-top mt-5 pt-3 w-75 d-flex  justify-content-center">
          <small>This payment is processed by</small>
          <img
            src={revolutLogo}
            height={10}
            className="pl-2"
            style={{ marginTop: '1px' }}
          />
        </div>
      </div>
    </div>
  );
};
