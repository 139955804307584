import React from 'react';
import { messages } from 'constants/messages';
import { windowHeight } from 'constants/height';

export default function Message(props) {
  const {
    match: {
      params: { message },
    },
  } = props;
  return (
    <div className="container" style={{ minHeight: windowHeight + 30 }}>
      <div className="row" style={{ marginTop: '100px' }}>
        <div className="col">
          <h1>{messages[message]}</h1>
        </div>
      </div>
    </div>
  );
}
