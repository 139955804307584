const env = process.env.ENVIRONMENT;

const PAYMENT_DEV = {
  merchant: {
    mid: '00000521',
    tid: '00009645',
  },
  script:
    'https://sandbox-truevo-drop-in-hosted-payment-widget.s3.eu-west-1.amazonaws.com/merchant.minified.js',
};

const PAYMENT_PROD = {
  merchant: {
    mid: '12003305',
    tid: '00003971',
  },
  script: 'https://pay.truevo.com/widget/merchant.minified.js',
};

function getConfig() {
  switch (env) {
    case 'dev':
      return PAYMENT_DEV;
    case 'pro':
      return PAYMENT_PROD;
    default:
      return {
        ...PAYMENT_DEV,
      };
  }
}

export default getConfig();
