import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { omit } from 'lodash';
import { IMAGE_DISPLAY, prefixImage } from '../../helpers/image';
import placeholder from '../../images/placeholder.png';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const ImageComponent = (props) => {
  const { image, type, handleImages } = props;
  const [src, setSrc] = useState(undefined);
  const [error, hasError] = useState(false);

  const onError = (e) => {
    if (!error) {
      setSrc(image.url);
      hasError(true);
    }
  };

  // THIS HANDLES IMAGE VIEWER OF A TOUR
  const afterLoad = () => {
    if (handleImages) {
      handleImages(error ? src : prefixImage(image, IMAGE_DISPLAY));
    }
  };

  useEffect(() => {
    if (image && type) {
      setSrc(prefixImage(image, type));
    }
  }, [image, type]);
  return (
    <LazyLoadImage
      src={src}
      effect="blur"
      onError={onError}
      placeholderSrc={placeholder}
      afterLoad={afterLoad}
      {...omit(props, 'handleImages')}
    />
  );
};
