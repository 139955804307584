import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Card } from 'primereact/card';
import { textField, textAreaField } from 'helpers/reduxForms';

export class ContactForm extends Component {
  onSubmit = (values) => {
    const { onSubmit, destroy } = this.props;

    destroy();
    onSubmit(values);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6" style={{ marginTop: 20 }}>
            <Card className="animated pulse">
              <div className="row section">
                <div className="col border-bottom mb-3 text-center">
                  <h2>Connect with us!</h2>
                </div>
              </div>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row section">
                  <div className="col">
                    <Field
                      name="fullname"
                      component={textField}
                      label="Full Name"
                    />
                  </div>
                </div>
                <div className="row section">
                  <div className="col">
                    <Field name="email" component={textField} label="Email" />
                  </div>
                </div>
                <div className="row section">
                  <div className="col">
                    <Field
                      name="message"
                      component={textAreaField}
                      rows={10}
                      label="Message"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-secondary btn-lg btn-block"
                    >
                      SEND EMAIL
                    </button>
                  </div>
                </div>
              </form>
            </Card>
            <div className="row" style={{ marginTop: '50px' }}>
              <div className="col text-left">
                <span className="font-weight-bold">Address: </span>
                <span>180, Tourist Street, Bugibba</span>
              </div>
            </div>
            <div className="row">
              <div className="col text-left">
                <span className="font-weight-bold">Telephone: </span>
                <span>+356 21582997 / +356 99335526</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'REQUIRED_EMAIL';
  } else {
    const regex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(String(values.email).toLowerCase())) {
      errors.email = 'INVALID_EMAIL';
    }
  }

  if (!values.password) {
    errors.password = 'REQUIRED_PASSWORD';
  }

  return errors;
};

export default reduxForm({
  form: 'contactForm',
  validate,
})(ContactForm);
