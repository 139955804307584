import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { Route, Switch, Link } from 'react-router-dom';
import logo from 'images/logo.png';
import Contact from 'routes/Contact';
import TermAndCondition from './TermAndCondition';
import PrivacyPolicy from './PrivacyPolicy';
import CancellationPolicy from './CancellationPolicy';
import AboutUs from './AboutUs';
import {
  PRIVACY_POLICY,
  CANCELLATION_POLICY,
  TERMS,
  CONTACT_US,
  ABOUT_US,
} from '../../constants/routes';

const versionNumber = process.env.REACT_APP_VERSION;

const Footer = () => (
  <React.Fragment>
    <div className="mt-5">
      <Switch>
        <Route path={PRIVACY_POLICY} component={PrivacyPolicy} />
        {/* <Route path={CANCELLATION_POLICY} component={CancellationPolicy} /> */}
        <Route path={TERMS} component={TermAndCondition} />
        <Route path={CONTACT_US} component={Contact} />
        <Route path={ABOUT_US} component={AboutUs} />
      </Switch>
    </div>

    <div id="footer" className="container-fluid">
      <Navbar
        id="mobile"
        sticky="bottom"
        expand="sm"
        variant="light"
        bg="light">
        <Container style={{ justifyContent: 'space-evenly' }}>
          <Link to={ABOUT_US} className="foot-link m-1">
            <i
              className="pi pi-info"
              style={{ display: 'block', textAlign: 'center' }}
            />
            <span>About Us</span>
          </Link>
          <Link to={PRIVACY_POLICY} className="foot-link m-1">
            <i
              className="pi pi-unlock"
              style={{ display: 'block', textAlign: 'center' }}
            />
            <span>Privacy Policy</span>
          </Link>
          <Link to={CANCELLATION_POLICY} className="foot-link m-1">
            <i
              className="pi pi-dollar"
              style={{ display: 'block', textAlign: 'center' }}
            />
            <span>Cancellation Policy</span>
          </Link>

          <Link to={TERMS} className="foot-link m-1">
            <i
              className="pi pi-file"
              style={{ display: 'block', textAlign: 'center' }}
            />
            <span>Terms and Conditions</span>
          </Link>
          <Link to={CONTACT_US} className="foot-link m-1">
            <i
              className="pi pi-question"
              style={{ display: 'block', textAlign: 'center' }}
            />
            <span>Contact Us</span>
          </Link>
        </Container>
      </Navbar>
      <Navbar id="pc" sticky="bottom" expand="sm" variant="light" bg="light">
        <Container>
          <Navbar.Brand>
            <img style={{ height: '40px' }} src={logo} alt="logo" />
          </Navbar.Brand>
          <div className="row" style={{ width: '100%' }}>
            <div className="col d-flex justify-content-around">
              <Link to={ABOUT_US} className="foot-link" href="/privacy">
                <h4 className="mb-2 mt-2">About Us</h4>
              </Link>
              <Link to={PRIVACY_POLICY} className="foot-link" href="/privacy">
                <h4 className="mb-2 mt-2">Privacy Policy</h4>
              </Link>
              {/* <Link
                to={CANCELLATION_POLICY}
                className="foot-link"
                href="/privacy">
                <h4 className="mb-2 mt-2">Cancellation Policy</h4>
              </Link> */}

              <Link to={TERMS} className="foot-link">
                <h4 className="mb-2 mt-2">Terms and Conditions</h4>
              </Link>
              <Link to={CONTACT_US} className="foot-link">
                <h4 className="mb-2 mt-2">Contact Us</h4>
              </Link>
            </div>
          </div>
        </Container>
      </Navbar>
      <center style={{ display: 'block' }}>
        <hr />
        <span>&copy; Copyright - 2022</span>
        {!!versionNumber && <span className="ml-4">v{versionNumber}</span>}
      </center>
    </div>
  </React.Fragment>
);

export default Footer;
