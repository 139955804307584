import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';

export default function Bullets(props) {
  const { label } = props;
  return (
    <ScrollAnimation animateIn="fadeInUp" animateOnce>
      <div style={{ margin: '0 20px 20px 20px' }}>
        <FontAwesomeIcon icon={faCircle} style={{ color: '#ce1011' }} />
        <span className="ml-3" style={{ color: '#000' }}>
          {label}
        </span>
      </div>
    </ScrollAnimation>
  );
}
