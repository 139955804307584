import React from 'react';
import { windowHeight } from 'constants/height';

const PrivacyPolicy = () => {
  return (
    <div className="container" style={{ minHeight: windowHeight }}>
      <div style={{ textAlign: 'justify' }}>
        <h3>
          <b>Privacy Policy</b>
        </h3>
        {/* Privacy Policy */}
        <p className="mt-3">
          This Privacy Policy describes the information that we gather on or
          through the Service, how we use and disclose such information, and the
          steps we take to protect such information. By visiting the Site, you
          accept the privacy practices described in this Policy. By clicking,
          the user is agreeing to our Privacy Policy terms.
        </p>
        <p className="mt-3">
          It is possible to enter and use our website without any indication of
          personal data; however, if you would like to use special features
          incorporated in our website, processing of personal data could become
          necessary. Our data protection declaration is based on the European
          Union’s General Data Protection Regulation (Regulation (EU) 2016/679,
          hereinafter “GDPR”).
        </p>
        <p className="mt-3">
          As the controller, Malta Tour has implemented numerous technical and
          administrative measures to ensure the most complete protection of
          personal data processed through this website.
        </p>
        {/* Collection of Information */}
        <h3 className="mt-5">
          <b>Collection of Information</b>
        </h3>
        <p className="mt-3">
          When you use the Service, as a User or as a Visitor, you may provide,
          and we may collect Personal Data.The processing of personal data, such
          as the name, address, e-mail address, or telephone/mobile of a data
          subject shall always be in line with the EU’s GDPR.
        </p>
        <p className="mt-3">
          We collect different types of information from you if and when you
          register on our site. The legal bases for Leisure 1st Ltd processing
          of personal data are primarily that the processing is necessary for
          providing the Service and that the processing is carried out in the
          legitimate interests.
        </p>
        {/* How do we use the information collected? */}
        <h3 className="mt-5">
          <b>How do we use the information collected?</b>
        </h3>
        <p className="mt-3">
          We use the information that we collect in a variety of ways in
          providing the Service and operating our business. Your information,
          whether public or private, will not be sold, exchanged, transferred,
          or given to any other company for any reason whatsoever, without your
          consent.
        </p>
        <p className="mt-3">
          We use the information to understand and analyse the usage trends and
          preferences of our visitors and Users, to improve the Service, and to
          develop new products, services, feature, and functionality.
        </p>
        <p className="mt-3">
          We may use a Visitor’s or User’s email address or other information –
          other than Client Data – to contact that Visitor or User (i) for
          administrative purposes such as customer service, to address
          intellectual property infringement, right of privacy violations or
          defamation issues related to the Client Data or Personal Data posted
          on the Service or (ii) with updates on promotions and events, relating
          to products and services offered by us.
        </p>
        {/* Cookies and Tracking Technologies */}
        <h3 className="mt-5">
          <b>Cookies and Tracking Technologies</b>
        </h3>
        <p className="mt-3">
          Cookies are small text files or pieces of information that are stored
          on your computer or mobile device when you visit our website. A cookie
          contains the name of the website and a value, which can be used for a
          variety of purposes. Some cookies are essential for the smooth
          operations of our website and necessary for the website to work.
        </p>
        <p className="mt-3">
          We use automatically collected information through cookies and similar
          technologies to: (i) personalise our Service, such as remembering a
          User’s or Visitor’s information so that the User or Visitor will not
          have to re-enter it during a visit or on subsequent visits; (ii)
          provide tailored advertisements, content, and information; (iii)
          monitor and analyse the effectiveness of Service and third-party
          marketing activities; (iv) monitor aggregate site usage metrics such
          as total number of visitors and pages viewed; and (v) track your
          entries, submissions, and status in any promotions or other activities
          on the Service.
        </p>
        <p className="mt-3">
          We take measures to protect the technical information collected by our
          use of Analytics. The data collected will only be used on a need to
          know basis to resolve technical issues, administer the Site and
          identify visitor preferences; but in this case, the data will be in
          non-identifiable form. We do not use any of this information to
          identify Visitors or Users.Our cookies do not contain any data that
          could be used to identify you personally.
        </p>
        {/* To Whom We Disclose Information */}
        <h3 className="mt-5">
          <b> To Whom We Disclose Information</b>
        </h3>
        <p className="mt-3">
          We will not intentionally disclose the Personal Data or Client Data
          that we collect or store to third parties without the consent of the
          applicable Visitor, User or Client. We may disclose information to
          third parties if you consent to us doing so, as well as in the
          following circumstances:
        </p>
        <p className="mt-3">
          - Service Providers- to third party service providers who provide
          website, application development, hosting, maintenance, and other
          services for us. We limit the information provided to these service
          providers to that which is reasonably necessary for them to perform
          their functions, and require them to maintain the confidentiality of
          such information.
        </p>
        <p className="mt-3">
          - Non- Personally Identifiable Information- we may pass such
          information to third parties for various purposes, including (i)
          compliance with various reporting obligations; (ii) for business or
          marketing purposes; or (iii) to assist such parties in understanding
          our Clients’, Users’ and Visitors’ interests, habits, and usage
          patterns for certain programs, content, services, and/or functionality
          available through the Service.
        </p>
        <p className="mt-3">
          - Law Enforcement, Legal Process and Compliance - We may disclose
          Personal Data or other information if required to do so by law or in
          the good-faith belief that such action is necessary to comply with
          applicable laws, enforce our site policies, take precautions against
          liability ,protect ourselves or others from fraudulent, abusive, or
          unlawful uses or activity, investigate and defend ourselves against
          any third-party claims or allegations, protect the security or
          integrity of the , protect our property or other legal rights, enforce
          our contracts, or protect the rights, property, or safety of others.
        </p>
        <p className="mt-3">
          - Change of Ownership - Personal Data and Client Data, may be
          disclosed and otherwise transferred (physically or electronically) to
          an acquirer, successor or assignee as part of any merger, acquisition,
          debt financing, sale of assets, or similar transaction, as well as in
          the event of an insolvency, bankruptcy, or in which information is
          transferred to one or more third parties as one of our business assets
          and only if the recipient or operator of the Service, as the case may
          be, of the User or Visitor Data commits to a Privacy Policy that has
          terms substantially consistent with this Privacy Policy.
        </p>
        {/* Period of Storage of Personal Data */}
        <h3 className="mt-5">
          <b> Period of Storage of Personal Data</b>
        </h3>
        <p className="mt-3">
          The criteria we use to determine the period of storage and storage
          limitation is in line with GDPR (Art 5(1)(e)) therefore, personal data
          shall not be retained longer than necessary, in relation to the
          purpose for which such data is processed.
        </p>

        <p className="mt-3">
          After expiration of that period, the corresponding data is routinely
          deleted, as long as it is no longer necessary for the fulfilment of
          the agreement or the initiation of an agreement between Client and
          Leisure 1st Ltd.
        </p>
        <p className="mt-3">
          If a Client demands the erasure of personal data concerning him or
          her, we endeavour to erase that personal data “without undue delay”
          unless contrary to our statutory rights and obligations, or if such
          personal data shall be required in the interest of public health,
          scientific or historical research, or for statistical purposes; Or
          whether such personal data is needed for the establishment, exercise
          or defence of legal claims.
        </p>
        {/* Consent */}
        <h3 className="mt-5">
          <b>Consent</b>
        </h3>
        <p className="mt-3">
          By using our site, you consent to our website's privacy policy, and
          our website's Cookie Policy and Tracking Technologies.
        </p>
        {/* Changes to our Privacy Policy */}
        <h3 className="mt-5">
          <b>Changes to our Privacy Policy</b>
        </h3>
        <p className="mt-3">
          If we decide to change our privacy policy, we will post those changes
          on this page.
        </p>
        {/* What are your rights under the GDPR ? */}
        <h3 className="mt-5">
          <b>What are your rights under the GDPR ?</b>
        </h3>
        <p className="mt-3">
          <ul className="list">
            <li className="mt-3">
              The right to be informed about the processing of your personal
              information
            </li>
            <li className="mt-3">
              The right to have your personal information corrected if it is
              inaccurate and to have incomplete personal information completed
            </li>
            <li className="mt-3">
              The right to object to processing of your personal information
            </li>
            <li className="mt-3">
              The right to restrict processing of your personal information
            </li>
            <li className="mt-3">
              The right to have your personal information erased (the “right to
              be forgotten”)
            </li>
            <li className="mt-3">
              The right to request access to your personal information and to
              obtain information about how we process it
            </li>
            <li className="mt-3">
              The right to move, copy or transfer your personal information
              (“data portability”)
            </li>
            <li className="mt-3">
              Rights in relation to automated decision making which has a legal
              effect or otherwise significantly affects you.
            </li>
          </ul>
        </p>
        <h3 className="mt-5">
          <b>Contacting us</b>
        </h3>
        <p className="mt-3">
          If there are any questions regarding this privacy policy you may
          contact us using the information below.
        </p>
        <p className="mt-3">
          <a
            href="mailto:info@malatour.travel"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@malatour.travel
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
