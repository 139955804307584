import React from 'react';
import { windowHeight } from 'constants/height';

const AboutUs = () => (
  <div className="container" style={{ minHeight: windowHeight }}>
    <div style={{ textAlign: 'justify' }}>
      <h3>
        <b>About Us</b>
      </h3>
      <p className="mt-3">
        {/* Based in the beautiful and tranquil Maltese Islands Malta Tour is a
        platform based of an idea which is designed to simplify the user
        experience during their stay in Malta. This is achieved by investing in
        the latest digital technologies to deliver to the user the activities
        that are most interesting but also the activities that are available
        during a set number of days. */}
        Immerse in the beauty of Malta through our innovative platform.
        Enhancing the tourist experience, we offer seamless access to
        captivating activities tailored to a vast range of interests and stay
        duration, in collaboration with our trusted partners.
      </p>
      <p className="mt-3">
        {/* Our aim is to make it easy for Tourists to access and buy activities
        from when they decide to travel to Malta to just a couple of days before
        the activity is set to take place. Either way the aim is that tourists
        can return home with the most memorable experience possible of the
        Maltese Islands. */}
        With nearly 30 years of combined experience, we ensure effortless
        activity access, leaving visitors with unforgettable memories.
      </p>
      <p className="mt-3 mb-5">
        {/* Although development of the platform was started in 2019, the idea is
        backed up by a group of professionals who have no less than 25 years’
        experience in local tourism. */}
        We deliver exceptional service and curate the best of Malta's activities
        through our network of reliable partners.
      </p>
      <h4>
        <b>Malta Tour offers</b>
      </h4>
      <p className="mt-4">
        <ul className="list">
          <li className="mt-3">Large selection of activities</li>
          <li className="mt-3">Competitive market rates</li>
          <li className="mt-3">Easy Cancellation terms</li>
          <li className="mt-3">Secure payment gateway</li>
          <li className="mt-3">
            Competent and professional staff available for assistance
          </li>
        </ul>
      </p>
    </div>
  </div>
);

export default AboutUs;
