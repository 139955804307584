import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { Card } from 'primereact/card';
import PickupPoint from 'components/layout/PickupPoint';
import { withRouter } from 'react-router';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button } from 'primereact/button';
import { windowHeight } from 'constants/height';
import { getCurrentUser, getUsers } from '../services/users/actions';
import { getPickups } from '../services/pickups/actions';
import { getImages } from '../services/gallery/actions';
import { getOrders, getUserOrders } from '../services/orders/actions';
import { getTours } from '../services/tours/actions';
import { formatTime } from '../helpers/dateTime';
import 'animate.css/animate.min.css';
import { checkIsLoggedIn } from '../helpers/authentication';
import { ImageComponent } from '../components/utils/ImageComponent';
import { IMAGE_DISPLAY } from '../helpers/image';

export class PrintOrder extends Component {
  componentDidMount() {
    const { getUserOrders, history } = this.props;
    if (!checkIsLoggedIn()) {
      history.push('/');
    }
    getUserOrders();
    setTimeout(() => {
      window.print();
    }, 1000);
    setTimeout(() => {
      history.goBack();
    }, 2000);
  }

  getTourItem = (tourId) => {
    const { tours } = this.props;
    const tourItem = tours
      ? tours.filter((tour) => tour.uuid === tourId)[0]
      : null;
    return tourItem;
  };

  getImageUrl(id) {
    const { images } = this.props;
    const image =
      images.length > 0 && images.filter((image) => image.uuid === id)[0]
        ? images.filter((image) => image.uuid === id)[0]
        : null;
    return image;
  }

  getDates = (date) => {
    let dates = null;
    try {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
      dates = `${days[date.toDate().getDay()]}, ${date.toDate().getDate()} ${
        months[date.toDate().getMonth()]
      } ${date.toDate().getFullYear()}`;
    } catch (e) {
      console.log(e);
    }
    return dates;
  };

  getUserinfo = (id) => {
    const { user } = this.props;
    return user || null;
  };

  getTourInfo = (id) => {
    const { tours } = this.props;
    return tours ? tours.filter((tour) => tour.uuid === id)[0] : null;
  };

  getPickupInfo = (id) => {
    const { pickups } = this.props;
    return pickups ? pickups.filter((pickup) => pickup.uuid === id)[0] : null;
  };

  getOrders = (isAgent) => {
    const { orders } = this.props;
    return orders
      ? orders
          .filter((order) => {
            if (isAgent || order.isprivate) {
              return order.user === Cookies.get('user');
            }

            return order.user === Cookies.get('user') && order.payment;
          })
          .sort(
            (a, b) =>
              b.ordered.toDate().getTime() - a.ordered.toDate().getTime(),
          )
      : [];
  };

  getSelectedPickup = (pickup, tour) => {
    const { pickups } = this.props;
    const tourPickup = tour.pickups.find((p) => p.id === pickup);
    const pickupPoint = pickups.find((p) => p.uuid === pickup);

    if (tourPickup && pickupPoint) {
      return (
        <div>
          You will be picked up from {pickupPoint.name} at {tourPickup.time}.
        </div>
      );
    }
    return '';
  };

  render() {
    const { user, location } = this.props;
    const isAgent = user && user.agent;
    const userOrder = this.getOrders(isAgent);
    const { data } = location.state;
    return (
      <div className="container" style={{ minHeight: windowHeight + 30 }}>
        <div className="row mt-3">
          <div className="col">
            <Card style={{ borderRaduis: '20px' }}>
              <div className="row">
                <div className="col">
                  <div className="row justify-content-md-center">
                    <div className="col-offset-lg-3 col-lg-6 text-center">
                      <ImageComponent
                        alt=""
                        height={140}
                        width="100%"
                        type={IMAGE_DISPLAY}
                        image={this.getImageUrl(
                          (this.getTourItem(data.tourid) &&
                            this.getTourItem(data.tourid).displayimage) ||
                            (this.getTourItem(data.tourid) &&
                              this.getTourItem(data.tourid).photos[0]),
                        )}
                        className="order-header"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-offset-lg-3 col-lg-6">
                      <div className="tour-details-container">
                        <div className="row">
                          <div className="col">
                            <h2>
                              <label>{data.tour && data.tour.name}</label>
                            </h2>
                          </div>
                        </div>
                        <div
                          className="row justify-content-md-center"
                          style={{ marginTop: '30px' }}
                        >
                          <div className="col text-center">
                            <h2>
                              <label className="theme-red">{data.ref}</label>
                            </h2>
                          </div>
                        </div>
                        <div className="row" style={{ marginBottom: '30px' }}>
                          <div className="col text-center">
                            <h4 className="theme-red">YOUR ORDER NUMBER</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col d-flex justify-content-between">
                            <span>{this.getDates(data.date)}</span>
                            <span>
                              {this.getTourInfo(data.tourid) &&
                                `${formatTime(
                                  this.getTourInfo(data.tourid).schedule.start,
                                )} - ${formatTime(
                                  this.getTourInfo(data.tourid).schedule.end,
                                )}`}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <hr />
                          </div>
                        </div>
                        {data.cancelled ? (
                          <div className="row">
                            <div className="col text-center">
                              <h1 className="fade-gray font-weight-bold">
                                CANCELLED
                              </h1>
                            </div>
                          </div>
                        ) : (
                          <Fragment>
                            {!data.isprivate ? (
                              <div className="row">
                                <div className="col d-flex justify-content-between">
                                  <span>Number of people</span>
                                  <span>
                                    {data.personcount.adult +
                                      (data.personcount.child || 0)}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="row">
                                <div className="col text-center">
                                  <span className="font-weight-bold">
                                    TOUR IS PRIVATE
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="row">
                              <div className="col">
                                <hr />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <span className="font-weight-bold">
                                  Transportation
                                </span>
                              </div>
                            </div>
                            <div>
                              {data.tour.transportType === 'no-transport' && (
                                <div>No transport is available.</div>
                              )}
                              {data.tour.transportType ===
                                'transport-included-in-price' &&
                                this.getSelectedPickup(data.pickup, data.tour)}
                              {data.tour.transportType ===
                                'transport-optional' &&
                                !data.pickup && (
                                  <div>You opted for no transport.</div>
                                )}
                              {data.tour.transportType ===
                                'transport-optional' &&
                                data.pickup &&
                                this.getSelectedPickup(data.pickup, data.tour)}
                            </div>
                            {/* <PickupPoint
                                tour={item.tour}
                                getPickupInfo={this.getPickupInfo}
                                formatTime={formatTime}
                              /> */}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ tours, gallery, orders, users, pickups }) => ({
      tours: tours.tours,
      images: gallery.images,
      orders: orders.userOrders,
      user: users.user,
      pickups: pickups.pickups,
    }),
    {
      getTours,
      getImages,
      getOrders,
      getUsers,
      getPickups,
      getCurrentUser,
      getUserOrders,
    },
  )(PrintOrder),
);
