export const GET_TOURS = 'GET_TOURS';
export const GET_SINGLE_TOUR = 'GET_SINGLE_TOUR';
export const SET_TOURS = 'SET_TOURS';
export const SET_SINGLE_TOUR = 'SET_SINGLE_TOUR';
export const UPDATE_TOUR_ORDER = 'UPDATE_TOUR_ORDER';
export const RATE_TOUR = 'RATE_TOUR';

export const GET_GUIDES = 'GET_GUIDES';
export const GET_SINGLE_GUIDE = 'GET_SINGLE_GUIDE';
export const SET_GUIDES = 'SET_GUIDES';
export const SET_SINGLE_GUIDE = 'SET_SINGLE_GUIDE';

export const GET_IMAGES = 'GET_IMAGES';
export const SET_IMAGES = 'SET_IMAGES';

export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const CREATE_USER = 'CREATE_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_WITH_GOOGLE = 'LOGIN_USER_WITH_GOOGLE';
export const LOGIN_USER_WITH_FACEBOOK = 'LOGIN_USER_WITH_FACEBOOK';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_LOGGING_STATUS = 'SET_LOGGING_STATUS';
export const SET_VERIFY_STATUS = 'SET_VERIFY_STATUS';
export const SET_VERIFY_STATUS_FALSE = 'SET_VERIFY_STATUS_FALSE';
export const LOGOUT = 'LOGOUT';
export const SEND_RESET_PASSWORD_EMAIL = 'SEND_RESET_PASSWORD_EMAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const DELETE_PROFILE = 'DELETE_PROFILE';

export const ADD_TO_CART = 'ADD_TO_CART';
export const GET_CART = 'GET_CART';
export const SET_CART = 'SET_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_CART = 'DELETE_CART';

export const APPLY_PAYMENT = 'APPLY_PAYMENT';
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';

export const ADD_ORDER = 'ADD_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const PURCHASE_ORDER = 'PURCHASE_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const SET_ORDERS = 'SET_ORDERS';
export const GET_USER_ORDERS = 'GET_USER_ORDERS';
export const SET_USER_ORDERS = 'SET_USER_ORDERS';

export const GET_COMMENTS = 'GET_COMMENTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const SET_COMMENTS = 'SET_COMMENTS';

export const GET_ENTRANCES = 'GET_ENTRANCES';
export const SET_ENTRANCES = 'SET_ENTRANCES';
export const ADD_ENTRANCE = 'ADD_ENTRANCE';
export const UPDATE_ENTRANCE = 'UPDATE_ENTRANCE';

export const GET_PICKUPS = 'GET_PICKUPS';
export const SET_PICKUPS = 'SET_PICKUPS';
export const ADD_PICKUP = 'ADD_PICKUPS';
export const DELETE_PICKUP = 'DELETE_PICKUPS';
export const UPDATE_PICKUP = 'UPDATE_PICKUPS';

export const GET_TRANSPORTS = 'GET_TRANSPORTS';
export const ADD_TRANSPORT = 'ADD_TRANSPORT';
export const UPDATE_TRANSPORT = 'UPDATE_TRANSPORT';
export const SET_TRANSPORTS = 'SET_TRANSPORTS';
export const DELETE_TRANSPORT = 'DELETE_TRANSPORT';

export const GET_RESTAURANTS = 'GET_RESTAURANTS';
export const SET_RESTAURANTS = 'SET_RESTAURANTS';
