import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import BookHeader from 'components/forms/booking/BookHeader';
import BookFooter from 'components/forms/booking/BookFooter';
import { error } from 'helpers/reactToaster';
import { CardPayment } from './PayByCard';

export class PayOnlineForm extends Component {
  componentDidMount() {
    const { onSubmit, formValues } = this.props;
    onSubmit(formValues);
  }

  onError = (e) => {
    console.log(e);
    error('Error on Payment');
  };

  onSubmit = (values) => {
    const { onSubmit } = this.props;
    onSubmit(values);
  };

  render() {
    const { handleSubmit, onPrevious, formValues, tour } = this.props;
    return (
      <div>
        <BookHeader page={4} title="Pay Online" />
        <div className="row" style={{ padding: '10px 50px' }}>
          <div className="col gray-form">
            <div className="row section">
              <div className="col">
                <CardPayment
                  handleSubmit={handleSubmit}
                  tour={tour}
                  formValues={formValues}
                />
              </div>
            </div>
          </div>
        </div>
        <BookFooter page={4} onPrevious={onPrevious} />
      </div>
    );
  }
}

export default reduxForm({
  form: 'booking', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(PayOnlineForm);
