import { MALTATOURS_CONFIG } from '../firebase-service/config';

export const actionCodeSettings = {
  // After password reset, the user will be give the ability to go back
  // to this page.
  url:
    process.env.REACT_APP_ENV === 'production'
      ? MALTATOURS_CONFIG.site
      : 'http://localhost:3000/login',
  handleCodeInApp: false,
};
