import React from 'react';
import moment from 'moment';

export default function renderSchedSwitch(schedule) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const text = [];
  const currentDate = moment().toDate();

  switch (schedule.type) {
    case 'Everyday':
      return (
        <div className="row fade-gray">
          <div className="col">
            <span>Everyday </span>
            {`${schedule.start} - ${schedule.end}`}
          </div>
        </div>
      );

    case 'Every Week':
      return (
        <div className="row fade-gray">
          <div className="col">
            {schedule.days.map(
              (day, i) =>
                `${day}s ${schedule.days.length - 2 === i ? ' and ' : schedule.days.length - 2 > i ? ', ' : ''}`,
            )}
          </div>
        </div>
      );

    case 'Specific Dates':
      for (let i = 0; i < schedule.dates.length; i++) {
        if (schedule.dates[i].toDate() >= currentDate) {
          text.push(
            `${
              i === 0
                ? `${months[schedule.dates[i].toDate().getMonth()]} ${schedule.dates[i].toDate().getDate()}`
                : schedule.dates[i].toDate().getMonth() ===
                    schedule.dates[i - 1].toDate().getMonth()
                  ? `${
                      currentDate.getMonth() ===
                      schedule.dates[i].toDate().getMonth()
                        ? `\n${months[schedule.dates[i].toDate().getMonth()]} ${schedule.dates[i].toDate().getDate()}`
                        : `${schedule.dates[i].toDate().getDate()}`
                    }`
                  : `\n${months[schedule.dates[i].toDate().getMonth()]} ${schedule.dates[i].toDate().getDate()}`
            }`,
          );

          text.push(
            i === schedule.dates.length - 2
              ? ' and '
              : i < schedule.dates.length - 2
                ? ', '
                : '',
          );
        }
      }
      return (
        <div className="row fade-gray">
          <div className="col">
            {text.length > 0 ? text : 'All schedules have already ended'}
          </div>
        </div>
      );

    default:
      return null;
  }
}
