import React from 'react';

function VerifyEmail() {
  return (
    <div className="container">
      <div className="row" style={{ marginTop: '100px' }}>
        <div className="col">
          <h1>Please check your email to verify your account.</h1>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
