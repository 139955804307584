import * as types from 'constants/actions';

const initState = {
  entrances: [],
};

export default (state = initState, action) => {
  const { entrances, type } = action;

  switch (type) {
    case types.SET_ENTRANCES:
      return { ...state, entrances };
    default:
      return { ...state };
  }
};
