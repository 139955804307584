import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Card } from 'primereact/card';
import { textField, passwordField } from 'helpers/reduxForms';

export class SignupForm extends Component {
  onSubmit = (values) => {
    const { onSubmit, destroy } = this.props;

    destroy();
    onSubmit(values);
  };

  render() {
    const { handleSubmit, isLoggingIn } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="auth-form col-lg-4 offset-lg-4">
            <Card>
              <div className="row">
                <div className="col border-bottom mb-3 text-center">
                  <h2>Sign Up</h2>
                </div>
              </div>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row">
                  <div className="col">
                    <Field
                      name="firstname"
                      component={textField}
                      label="First Name"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Field
                      name="lastname"
                      component={textField}
                      label="Last Name"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Field
                      name="email"
                      component={textField}
                      label="Email"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <Field
                      name="password"
                      component={passwordField}
                      label="Password"
                      autoComplete="new-password"
                    />
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-secondary btn-lg btn-block"
                      disabled={isLoggingIn}>
                      {isLoggingIn ? (
                        <i className="pi pi-spin pi-spinner" />
                      ) : (
                        'Sign Up'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'REQUIRED_EMAIL';
  } else {
    const regex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(String(values.email).toLowerCase())) {
      errors.email = 'INVALID_EMAIL';
    }
  }

  if (!values.password) {
    errors.password = 'REQUIRED_PASSWORD';
  } else if (values.password.length < 6) {
    errors.password = 'INVALID_PASSWORD_LENGTH';
  }

  return errors;
};

export default reduxForm({
  form: 'signupForm',
  validate,
})(SignupForm);
