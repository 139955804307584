import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Card } from 'primereact/card';
import { textField, passwordField } from 'helpers/reduxForms';
import { Link } from 'react-router-dom';

export class LoginForm extends Component {
  onSubmit = (values) => {
    const { onSubmit } = this.props;
    onSubmit(values);
  };

  render() {
    const { handleSubmit, isLoggingIn } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="auth-form col-lg-4 offset-lg-4">
            <Card className="mt-3 animated pulse">
              <div className="row">
                <div className="col border-bottom mb-3 text-center">
                  <h2>Log In</h2>
                </div>
              </div>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row">
                  <div className="col">
                    <Field name="email" component={textField} label="Email" />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <Field
                      name="password"
                      component={passwordField}
                      label="Password"
                      feedback={false}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  {/* <div className="col-6 mt-1">
                    <Field
                      name="rememberme"
                      component={rememberMe}
                      label="Remember Me"
                  />
                  </div> */}
                  <div className="col mt-5">
                    <button
                      type="submit"
                      className="btn btn-secondary btn-lg btn-block"
                      disabled={isLoggingIn}>
                      {isLoggingIn ? (
                        <i className="pi pi-spin pi-spinner" />
                      ) : (
                        'Log In'
                      )}
                    </button>
                  </div>
                </div>
              </form>
              <div className="row" style={{ marginTop: 50 }}>
                <div className="col text-center">
                  <span>Don't have an account? </span>
                  <Link to="/signup">Sign Up</Link>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col text-center">
                  <Link to="/forgotPassword">Forgot your password?</Link>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'REQUIRED_EMAIL';
  } else {
    const regex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(String(values.email).toLowerCase())) {
      errors.email = 'INVALID_EMAIL';
    }
  }

  if (!values.password) {
    errors.password = 'REQUIRED_PASSWORD';
  }

  return errors;
};

export default reduxForm({
  form: 'loginForm',
  validate,
})(LoginForm);
