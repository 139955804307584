import * as types from 'constants/actions';

const initState = {
  transports: [],
};

export default (state = initState, action) => {
  const { transports, type } = action;

  switch (type) {
    case types.SET_TRANSPORTS:
      return { ...state, transports };
    default:
      return { ...state };
  }
};
